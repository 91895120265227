<template>
  <div>
    <h5>Dashboard</h5>
    <select-language></select-language>
    <hr>
    <user-analytics v-if="user && user.company"></user-analytics>
    <admin-analytics v-if="user && !user.company &&  isAllowed('analytics', 'view')"></admin-analytics>
    <partners-dashboard v-if="user && isAllowed('my_commissions', 'view')"></partners-dashboard>
  </div>
</template>
<script>
import AdminAnalytics from '../../../System/Analytics/AdminAnalytics.vue'
import PartnersDashboard from '../../../System/Analytics/PartnersDashboard.vue'
import UserAnalytics from '../../../System/Analytics/UserAnalytics.vue'
import SelectLanguage from '../Components/SelectLanguage.vue'

export default {
  components: { UserAnalytics, AdminAnalytics, SelectLanguage, PartnersDashboard },
  data () {
    return {
      user: null
    }
  },
  created: function () {
    this.user = this.$store.state.user
    
    if (this.$route.query.refresh) {
      this.$router.push({ name: 'Home' })
      window.location.reload()
    }
  }
}

</script>
<style>

</style>
