<template>
  <view-mail :mail_id="mail.id" :validate="validate"></view-mail>
</template>
<script>
import ViewMail from '../Mailbox/ViewMail.vue';

export default {
  components: { ViewMail },
  props: ['mail', 'validate'],
  data() {
    return {
     
    };
  },
  watch: {
  
  },
  created: function () {
  },
  methods: {
   
    
  },
};
</script>
<style lang="scss"></style>

