<template>
  <ValidationObserver ref="form" v-loading.fullscreen.lock="loading">
    <form @submit.prevent="validate">
      <h5 class="info-text">Update your profile</h5>
      <div class="row">
        <div class="col-md-6" v-if="form.type != null">
          <div class="form-group">
            <label>{{ form.type == 1 ? 'Company Name' : 'Name' }}</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <el-input v-model="form.name" class=" form-group" :class="failed ? ' has-danger' : passed"></el-input>
              <small class="invalid-feedback error-text" v-if="failed">This field is required</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-4" v-if="form.type != null">
          <div class="form-group">
            <label>Telephone Number</label>
            <el-input v-model="form.telephone" class=" form-group"></el-input>
          </div>
        </div>
        <div class="col-md-4" v-if="form.type != null">
          <div class="form-group">
            <label>Mobile Number</label>
            <el-input v-model="form.mobile_number" class=" form-group"></el-input>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Zelle Number</label>
            <el-input v-model="form.zelle_number" class=" form-group"></el-input>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>ACA Number</label>
            <el-input v-model="form.aca_number" class=" form-group"></el-input>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Account Number</label>
            <el-input v-model="form.account_number" class=" form-group"></el-input>
          </div>
        </div>

        <div class="col-md-12">
          <el-link :href="form.public_url" download target="_blank" class="text-primary">Download your W9 <i
              class="fa fa-file"></i></el-link>
          <br><br>
          <ValidationProvider name="file_name" :rules="!form.public_url ? required : null" v-slot="{ failed }">
            <el-input v-model="form.file_name" style="display: none;"></el-input>
            <file-upload @change="setFiles($event)" :multiple="false"></file-upload>
            <small class="invalid-feedback error-text" v-if="failed">File is required</small>
          </ValidationProvider>
        </div>

        <div class="col-md-12">
          <hr>
          <el-button size="small" type="success" native-type="submit">SAVE</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required,
  email
} from "vee-validate/dist/rules";
import FileUpload from '../../Dashboard/Views/Components/FileUpload.vue';

import {
  User
} from '@/resources/user';

extend("required", required);
extend("email", email);

export default {
  components: { FileUpload },
  props: ['selected'],
  data() {
    return {
      loading: false,
      form: {
        file: null,
        file_name: null
      },
      files: []
    };
  },
  watch: {
    selected: function () {
      this.form = Object.assign({}, this.selected)
    },
    trigger: function () {
      this.validate()
    },
  },
  created: function () {
    this.form = Object.assign({}, this.selected)
  },
  methods: {
    getW9Template() {
      return process.env.VUE_APP_API_ROOT + '/w9.pdf'
    },
    setFiles(files) {
      this.form.file = null
      this.form.file_name = null
      if (files) {
        this.form.file = files[0].raw
        this.form.file_name = files[0].name
      }
    },
    handleSubmit() {
      var formData = new FormData()
      formData.append('name', this.form.name)
      formData.append('type', this.form.type)
      formData.append('telephone', this.form.telephone || '')
      formData.append('mobile_number', this.form.mobile_number || '')
      formData.append('zelle_number', this.form.zelle_number || '')
      formData.append('aca_number', this.form.aca_number || '')
      formData.append('account_number', this.form.account_number || '')
      formData.append('file', this.form.file)

      this.loading = true

      User.savePartnersInfo(formData)
        .then(result => {
          this.$store.commit('saveUser', result.data.user);
          this.$notify({
            type: 'success',
            title: "Success",
            text: "Profile saved",
          });
        })
        .catch(error => {

        }).finally(() => {
          this.loading = false
        })
    },
    validate() {
      this.$refs.form.validate().then(res => {
        if (res) this.handleSubmit()
      })      
    }
  }
}
</script>

<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
