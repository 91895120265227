<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="info-text">{{ '' }}</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Upload your W9 form</label>
            <div>
              <el-link :href="getW9Template() " download target="_blank" class="text-primary">(Click to download the W9 form as your reference <i class="fa fa-file"></i>)</el-link>
              <br><br>
            </div>
            <ValidationProvider name="file_name" rules="required" v-slot="{ failed }">
              <el-input v-model="form.file_name" style="display: none;"></el-input>
              <file-upload @change="setFiles($event)" :multiple="false"></file-upload>
              <small class="invalid-feedback error-text" v-if="failed">File is required</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-md-6">
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required,
  email
} from "vee-validate/dist/rules";
import FileUpload from '../../Dashboard/Views/Components/FileUpload.vue';

extend("required", required);
extend("email", email);

export default {
  components: { FileUpload },
  props: ['selected', 'trigger'],
  data() {
    return {
      form: {
        file: null,
        file_name: null
      },
      files: []
    };
  },
  watch: {
    selected: function () {
      this.form = Object.assign({}, this.selected)
    },
    trigger: function () {
      this.validate()
    },
  },
  created: function () {
    this.form = Object.assign({}, this.selected)
  },
  methods: {
    getW9Template() {
      return process.env.VUE_APP_API_ROOT+'/w9.pdf'
    },
    setFiles(files) {
      this.form.file = null
      this.form.file_name = null
      if (files) {
        this.form.file = files[0].raw
        this.form.file_name = files[0].name
      }
    },
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('on-validated', res, this.form, 'w9')
        return res
      })
    }
  }
}
</script>

<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
