<template>
  <div class="row" v-loading.fullscreen.lock="loading">
    <el-alert type="warning" class="mb-3">
      Note: Scheduled deductions will occur from your balance or payment card every two weeks or once you hit the
      predefined maximum expenses limit.
    </el-alert>
    <el-dialog title="Pay due amount" :visible.sync="showPayment" width="400px">
      <payment-button :label="'$'+amount_to_pay" :amount="amount_to_pay" :type="'due_amount_payment'"
        :description="'Due amount payment'" @success="onPaymentSucceed()" @error="informError()"></payment-button>
    </el-dialog>

    <div class="col-md-4">
      <circle-chart-card :percentage="percentage()" chart-id="email-statistics-chart" title="Due Amount"
        :description="'Based from $'+user.max_charges+' maximum expenses'" :color="getColor()">
        <template slot="footer">
          <div class="legend">
            <i class="fa fa-circle text-info"></i> ${{user.unpaid_charges}}
          </div>
          <hr>
        </template>
      </circle-chart-card>

      <div class="mb-3">
        <label>Slide to set your payment</label>
        <el-slider v-model="amount_to_pay" show-input :max="getMax()" :min="getMin()" :step=".50"
          :disabled="parseFloat(this.user.unpaid_charges) <= 1" input-size="large"></el-slider>
      </div>
      <el-button type="success" class="btn-block" @click="onShowPayment()" :disabled="user.unpaid_charges < 1">Pay ${{
        amount_to_pay }} Now</el-button>
      <label class="gray-text">Payment is only available for $1 above</label>
    </div>
    <div class="col-md-8">
      <el-card>
        <h5>Pending Charges</h5>
        <el-table class="table-striped" header-row-class-name="text-primary" :data="user_charges" style="width: 100%">
          <el-table-column :min-width="220" class-name="td-actions" label="Description">
            <template slot-scope="props">
              {{ props.row.description }}
            </template>
          </el-table-column>
          <el-table-column :min-width="150" class-name="td-actions" label="Total Amount To Pay">
            <template slot-scope="props">
              {{ money(props.row.total_amount) }}
            </template>
          </el-table-column>
          <el-table-column :min-width="120" class-name="td-actions" label="Paid Amount">
            <template slot-scope="props">
              {{ money( props.row.total_amount - props.row.amount) }}
            </template>
          </el-table-column>
          <el-table-column :min-width="70" class-name="td-actions" label="Remaining">
            <template slot-scope="props">
              {{ money(props.row.amount) }}
            </template>
          </el-table-column>

        </el-table>
      </el-card>
    </div>

  </div>
</template>

<script>

import CircleChartCard from '../../UIComponents/Cards/CircleChartCard.vue'
import { mapState } from 'vuex';
import PaymentButton from '../../UIComponents/PaymentButton.vue';
import Swal from 'sweetalert2'
import { User } from '@/resources/user'
import { UserCharge } from '@/resources/user_charge'

export default {
  computed: mapState(['user']),
  components: { CircleChartCard, PaymentButton },
  name: 'pay-due-amount',
  data() {
    return {
      amount: 0,
      amount_to_pay: 0,
      showPayment: false,
      loading: false,
      user_charges: []
    }
  },
  created: function () {
    if (this.user) {
      this.amount = parseFloat(this.user.unpaid_charges)
      this.amount_to_pay = this.amount
      this.getUserCharges()
    }
  },
  watch: {
    user: {
      handler: function (newVal) {
          // this.amount = parseFloat(this.user.unpaid_charges)
        console.log('updated')
      },
      deep: true
    },
    amount: function () {
      return this.amount
    }
  },
  methods: {
    getUserCharges() {
      this.loading = true
      UserCharge.get({})
        .then(result => {
          this.user_charges = result.data.user_charges
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    getColor() {
      if (this.percentage() < 69) return '#4acccd'
      if (this.percentage() <= 85) return '#fbc658'

      return '#dc3545'
    },
    onShowPayment() {
      this.showPayment = true
    },
    informError() {
      alert("An error occured. Page will reload")
    },
    async onPaymentSucceed() {
      let html = "<p>Thank you for proceeding with the payment. By completing the payment process," +
        "you confirm that you have read, understood, and agreed to be bound by the Terms and Conditions" +
        "and Agreement for Credit Card Payment outlined above.<p>" +
        "<p>If you have any questions or require further clarification, please contact our customer support team. We value your business and look forward to providing you with exceptional services.</p>" +
        "<p>Thank you for choosing Biz-Depot."

      const { value: res } = await Swal.fire({
        title: "Thank you for paying the due amount",
        html: html,
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.showPayment = false
      this.amount = parseFloat(this.user.unpaid_charges)
      this.amount_to_pay = this.amount
      this.getUserCharges()
    },
   percentage() {
      return this.amount ? Math.round((parseFloat(this.amount) / this.user.max_charges) * 100) : 0;
    },
    getMax() {
      if (parseFloat(this.user.unpaid_charges) > this.user.max_charges) return parseFloat(this.user.unpaid_charges)

      return parseFloat(this.user.unpaid_charges)
    },
    getMin() {
      return 1
    },
  },
}
</script>

<style scoped>
.card-content {
  min-height: 85px
}
</style>
