<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Update Commission
          </h4>
          <p v-if="user" class="text-success">{{ user.name }}</p>
          <small>
            Modifying the commission rate will not impact previously recorded commissions and will only apply to future
            transactions.</small><br>
          <hr>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Rate</label>
            <ValidationProvider name="rate" v-slot="{ failed }">
              <el-input name="rate" type="number" v-model="form.compensation_rate" :class="failed ? 'input-error' : ''"></el-input>
              <small id="help" class="text-danger" v-if="failed">Rate is required</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { UserReferral } from '@/resources/user_referral'

extend("required", required);

export default {
  props: ['user'],
  data() {
    return {
      form: {
        compensation_rate: "",
      },
    
      loading: false,
    };
  },
  watch: {
    user: function () {
      if (this.user) {
        this.form.compensation_rate = this.user.user_referral.compensation_rate
      }
    }
  },
  created: function () {
    if (this.user) {
      this.form.compensation_rate = this.user.user_referral.compensation_rate
    }
  },
  methods: {
    submit() {
      this.loading = true
      UserReferral.updateCommissionRate(this.user.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Commission rate has been updated',
          });
         this.$emit('update', true)
        })
        .catch(error => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
