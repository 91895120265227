<template>
  <div v-loading.fullscreen.lock="loading">
    <h4>Good Day Partner!</h4><br>
    <h5>Kindly provide the necessary information to proceed with finalizing the partners page agreement</h5>

    <el-dialog title="" :visible.sync="showReview" width="40%" :append-to-body="true" :show-close="true"
      :close-on-click-modal="true">
      <div>
        <el-descriptions title="Kindly review the details you have submitted." :column="1">
          <el-descriptions-item label="Name">{{form.name}}</el-descriptions-item>
          <el-descriptions-item label="Telephone">{{form.telephone}}</el-descriptions-item>
          <el-descriptions-item label="Mobile No">{{form.mobile_number}}</el-descriptions-item>

          <el-descriptions-item label="Zelle">{{ account_form.zelle_number }}</el-descriptions-item>
          <el-descriptions-item label="ACA #">{{ account_form.aca_number }}</el-descriptions-item>
          <el-descriptions-item label="Account #">{{ account_form.account_number }}</el-descriptions-item>
          <el-descriptions-item label="W9 Form">
            <el-link @click="downloadFile(w9_form.file)" class="text-primary">Download <i
                class="fa fa-download"></i></el-link>
          </el-descriptions-item>
        </el-descriptions>

        <div style="text-align: center;"> <el-button type="primary" size="small"
            @click="handleSaveForm()">SUBMIT</el-button>
        </div>
      </div>
    </el-dialog>

    <div class="container">

      <el-steps :space="200" :active="active" simple finish-status="success">
        <el-step title="Basic Information" icon="el-icon-edit">
          test

        </el-step>
        <el-step title="W9 Form" icon="el-icon-upload">
          step 2
          {{ active }}
        </el-step>
        <el-step title="Payment Account Details" icon="el-icon-picture">
          step 3
          {{ active }}
        </el-step>
      </el-steps>


      <ValidationObserver ref="form">
        <form @submit.prevent="validate">
          <el-card v-if="active == 0">
            <div class="container">
              <partner-basic-info-form @on-validated="updateForm" :selected="form"
                :trigger="trigger"></partner-basic-info-form>
            </div>
          </el-card>
          <el-card v-if="active == 1">
            <div class="container">
              <partner-w-9-form @on-validated="updateForm" :selected="w9_form" :trigger="trigger"></partner-w-9-form>
            </div>
          </el-card>
          <el-card v-if="active == 2">
            <div class="container">
              <partner-account-form @on-validated="updateForm" :selected="account_form"
                :trigger="trigger"></partner-account-form>
            </div>
          </el-card>

          <hr>
          <div style="text-align: center;">
            <el-button size="small" type="primary" native-type="button" @click="handleBack" :disabled="active <= 0"><i
                class="fa fa-arrow-left"></i> Back</el-button>
            <el-button size="small" type="primary" native-type="submit">{{active < 2 ? 'Next' :'Submit'}} <i
                class="fa fa-arrow-right" v-if="active < 2"></i></el-button>
          </div>
        </form>
      </ValidationObserver>

    </div>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex';
import PartnerAccountForm from './PartnerAccountForm.vue';
import PartnerBasicInfoForm from './PartnerBasicInfoForm.vue';
import PartnerW9Form from './PartnerW9Form.vue';
import {
  User
} from '@/resources/user';

export default {
  computed: mapState(['user']),
  components: {
    PartnerBasicInfoForm,
    PartnerW9Form,
    PartnerAccountForm
  },
  data() {
    return {
      showReview: false,
      trigger: 0,
      loading: false,
      active: 0,
      form: {
        name: null,
        type: null
      },
      w9_form: {
        file: null,
        file_name: null
      },
      account_form: {

      }
    }
  },
  created: function () {
   },
  methods: {
    handleSaveForm() {
      var formData = new FormData()
      formData.append('name', this.form.name)
      formData.append('type', this.form.type)
      formData.append('telephone', this.form.telephone)
      formData.append('mobile_number', this.form.mobile_number)
      formData.append('zelle_number', this.account_form.zelle_number)
      formData.append('aca_number', this.account_form.aca_number)
      formData.append('account_number', this.account_form.account_number)
      formData.append('file', this.w9_form.file)

      this.loading = true

      User.savePartnersInfo(formData)
        .then(result => {
          this.$store.commit('saveUser', result.data.user);
          this.$notify({
            type: 'success',
            title: "Success",
            text: "",
          });
          let self = this
          setTimeout(function () {
            window.location.reload();
          }, 1000); // 2000 milliseconds = 2 seconds
        })
        .catch(error => {

        }).finally(() => {
          this.loading = false
        })
    },
    downloadFile(file) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(file);
      link.download = file.name; // Use the file's name for download
      link.click();
      URL.revokeObjectURL(link.href);
    },
    handleBack() {
      this.active -= 1
    },
    updateForm(result, form, type) {
      if (type == 'basic') this.form = Object.assign({}, form)
      if (type == 'w9') this.w9_form = Object.assign({}, form)
      if (type == 'account') this.account_form = Object.assign({}, form)
      if (this.active < 2) {
        this.active += 1
        return
      }
      this.showReview = true
    },
    handleSubmit(result) {
      if (!result) return
      this.trigger++
    },
    validate() {
      this.$refs.form.validate().then(res => {
        this.handleSubmit(res)
      })
    }
  }
}
</script>

<style lang="scss"></style>
