<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="info-text">{{ '' }}</h5>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Partner Type</label>

            <ValidationProvider name="type" rules="required" v-slot="{ passed, failed }">
              <el-select v-model="form.type" class=" form-group" :class="failed ? ' has-danger' : passed">
                <el-option :value="0" label="Person">Person</el-option>
                <el-option :value="1" label="Company/Organization">Company/Organization</el-option>
              </el-select>
              <small class="invalid-feedback error-text" v-if="failed">Type is required</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-md-9"></div>

        <div class="col-md-6" v-if="form.type != null">
          <div class="form-group">
            <label>{{ form.type == 1 ? 'Company Name':'Name' }}</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <el-input v-model="form.name" class=" form-group" :class="failed ? ' has-danger' : passed"></el-input>
              <small class="invalid-feedback error-text" v-if="failed">This field is required</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-4" v-if="form.type != null">
          <div class="form-group">
            <label>Telephone Number</label>
            <el-input v-model="form.telephone" class=" form-group"></el-input>
          </div>
        </div>
        <div class="col-md-4" v-if="form.type != null">
          <div class="form-group">
            <label>Mobile Number</label>
            <el-input v-model="form.mobile_number" class=" form-group"></el-input>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required,
  email
} from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
  props: ['selected', 'trigger'],
  data() {
    return {
      form: {
        type: null
      }
    };
  },
  watch: {
    selected: function () {
      if (this.selected) {
        this.form = Object.assign({}, this.selected)
      }
    },
    trigger: function () {
      this.validate()
    },
    form: {
      handler: function (newVal) {
        // this.$emit('on-validated', true, this.form, 'basic')
      },
      deep: true
    },
  },
  created: function () {
    if (this.selected) {
      this.form = Object.assign({}, this.selected)
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('on-validated', res, this.form, 'basic')
        return res
      })
    }
  }
}
</script>

<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
