<template>
  <el-descriptions title="" :column="1">
    <el-descriptions-item label="Name">{{ profile.name }}</el-descriptions-item>
    <el-descriptions-item label="Telephone">{{ profile.telephone }}</el-descriptions-item>
    <el-descriptions-item label="Mobile No">{{ profile.mobile_number }}</el-descriptions-item>

    <el-descriptions-item label="Zelle">{{ profile.zelle_number }}</el-descriptions-item>
    <el-descriptions-item label="ACA #">{{ profile.aca_number }}</el-descriptions-item>
    <el-descriptions-item label="Account #">{{ profile.account_number }}</el-descriptions-item>
    <el-descriptions-item label="W9 Form">
      <el-link @click="downloadFile()" class="text-primary">Download <i
          class="fa fa-download"></i></el-link>
    </el-descriptions-item>
  </el-descriptions>
</template>

        
<script>

export default {
  props: ['profile'],
  data() {
    return {
      loading: false,
    }
  },
  created: function () {
    // this.getUser(this.$route.params.id)
  },
  methods: {
    downloadFile() {
      const link = document.createElement('a');
      console.log(this.profile)

      if (this.profile.file) {
        let file = this.profile.public_url
        link.href = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.download = file.name; // Use the file's name for download
        link.click();
        URL.revokeObjectURL(link.href);
        return
      }

      if (this.profile.public_url) {
        link.href = this.profile.public_url;
        link.target = '_blank'
        link.download = this.profile.file_name; // Use the file's name for download
        link.click();
      }
    },
  },
}
</script>
        
<style lang="scss"></style>
