<template>
  <div>
    <div class="row" v-loading.fullscreen.lock="loading">
      <div class="col-md-4">
        <user-referral-card></user-referral-card>
      </div>
      <div class="col-md-5">
        <top-referred-users></top-referred-users>
      </div>


      <div class="col-md-3"></div>


    </div>

  </div>
</template>

<script>
import {
  mapState
} from 'vuex';
import TopReferredUsers from './TopReferredUsers.vue';
import UserReferralCard from './UserReferralCard.vue'

export default {
  computed: mapState(['user']),
  components: {
    UserReferralCard,
    TopReferredUsers,
  },
  data() {
    return {
      loading: false,
    }
  },
  created: function () {},
  methods: {

  }
}
</script>

<style lang="scss"></style>
