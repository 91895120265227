<template>
<div>
  <div class="row" v-loading.fullscreen.lock="loading">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-6">
          <sales-this-month></sales-this-month>
        </div>
        <div class="col-md-6">
          <all-pending-mail-requests></all-pending-mail-requests>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" style="margin-bottom: 10px">
          <top-users></top-users>
        </div>
        <!-- <div class="col-md-12 mt-3">
          <user-referral-card></user-referral-card>
        </div> -->
      </div>
    </div>
    <div class="col-md-6">
      <all-mails-received-in-a-year></all-mails-received-in-a-year>
    </div>
  </div>
</div>
</template>

<script>
import {
  Analytic
} from '@/resources/analytics'
import TopUsers from './TopUsers.vue'
import SalesThisMonth from './SalesThisMonth.vue'
import AllPendingMailRequests from './AllPendingMailRequests.vue'
import AllMailsReceivedInAYear from './AllMailsReceivedInAYear.vue'
import UserReferralCard from './UserReferralCard.vue'

export default {
  components: {
    TopUsers,
    SalesThisMonth,
    AllPendingMailRequests,
    AllMailsReceivedInAYear,
    UserReferralCard
  },
  data() {
    return {
      unread_mails: 0,
      pending_mail_requests: 0,
      loading: false,
    }
  },
  created: function () {
    // this.getAnalytics()
    // this.getPendingMailRequests()
  },
  methods: {
    getAnalytics() {
      this.loading = true
      Analytic.unreadMails()
        .then(result => {
          this.unread_mails = result.data
        })
        .catch(() => {}).finally(() => {
          this.loading = false
        })
    },
    getPendingMailRequests() {
      this.loading = true
      Analytic.pendingMailRequests()
        .then(result => {
          this.pending_mail_requests = result.data
        })
        .catch(() => {}).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
