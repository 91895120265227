<template>
  <div>
    <el-drawer  :visible.sync="show" direction="rtl" :size="600"
        :before-close="handleClose">
         <Translator :countries="countries" @on-country-click="checkLanguage($event)"
      />
      </el-drawer>
      <el-button @click="show = true" type="success" size="mini" plain>Change Language</el-button>
  </div>
</template>

<script>

import { Translator } from "vue-google-translate";

export default {
  components: { Translator },
  data() {
    return {
      show: false,
      countries: [
        {
          code: "en|es",
          title: "Spanish",
        },
        {
          code: "en|en",
          title: "English",
        },
      ]
    };
  },
  methods: {
    handleClose() {
      this.show = false
    },
    checkLanguage() {
      this.show = false
    }
  }
}
</script>
<style></style>
