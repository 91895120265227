import api from './api'

const API = '/api/user-referrals'

export const UserReferral = {
  get: function(params) {
    return api.get(API, params)
  },
  getReferredUsers: function(params) {
    return api.get(API+'/referred-users', {params: params})
  },
  findByCode: function(params) {
    return api.get(API+'/find-by-code', {params: params})
  },
  getMonthylCollections: function(params) {
    return api.get(API+'/monthly-collections', {params: params})
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  setUserReferrer: function(params) {
    return api.create(API+'/set-user-referrer', params)
  },
  downloadCommissions: function(params) {
    return api.create(API+'/download-commissions', params)
  },
  downloadClaimedCommissions: function(params) {
    return api.create(API+'/download-claimed-commissions', params)
  },
  payCommission: function(params) {
    return api.create(API+'/pay-commission', params)
  },
  updateCommissionRate: function(user_id, form) {
    return api.update(API+'/'+user_id+'/update-commission-rate', form)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
