<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="info-text">{{ '' }}</h5>
      <div class="row">

        <div class="col-md-4">
          <div class="form-group">
            <label>Zelle Number</label>
            <el-input v-model="form.zelle_number" class=" form-group"></el-input>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>ACA Number</label>
            <el-input v-model="form.aca_number" class=" form-group"></el-input>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Account Number</label>
            <el-input v-model="form.account_number" class=" form-group"></el-input>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required,
  email
} from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
  props: ['selected', 'trigger'],
  data() {
    return {
      form: {
        type: null
      }
    };
  },
  watch: {
    selected: function () {
      if (this.selected) {
        this.form = Object.assign({}, this.selected)
      }
    },
    trigger: function () {
      this.validate()
    },
  },
  created: function () {
    if (this.selected) {
      this.form = Object.assign({}, this.selected)
    }
  },
  methods: {
    handleNext() {

    },
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('on-validated', res, this.form, 'account')
        return res
      })
    }
  }
}
</script>

<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
