<template>
<div class="row">

  <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
    <div style="padding: 10px;">
      <p>Remove this item? </p>
      <p-button type="success" size="sm" icon @click="deleteItem()">
        <i class="fa fa-edit"></i>
      </p-button>
    </div>

  </el-drawer>

  <el-drawer title="New" :visible.sync="showForm" direction="rtl" :before-close="handleClose">
    <div style="padding: 10px;">
      <user-edit-form :user="selected"></user-edit-form>
    </div>
  </el-drawer>

  <div class="col-md-12 card">
    <div class="card-header">
      <div class="category">Users</div>
    </div>
    <div class="card-body row">
      <div class="col-sm-6">
        <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
          <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2">
        <p-button type="primary" size="sm" style="float:right" @click="handleNew()">
          <i class="fa fa-plus"></i> NEW
        </p-button>
      </div>
      <div class="col-sm-3">
        <div class="pull-right">
          <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
          </fg-input>
        </div>
      </div>
      <div class="col-md-12">
        <el-radio v-model="status" :label="1">Active</el-radio>
        <el-radio v-model="status" :label="2">Inactive</el-radio>
      </div>
      <div class="col-sm-12 mt-2" v-loading="loading">
        <el-table class="table-striped" header-row-class-name="text-primary" :data="users" style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="props">
              <div style="padding-left: 50px">
                <p>Roles: {{ props.row.role_names.join(", ") }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-for="column in userColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label">
          </el-table-column>
          <el-table-column :min-width="120" class-name="td-actions" label="Status">
            <template slot-scope="props">
              <el-tag v-if="props.row.status == 0">PENDING ACTIVATION</el-tag>
              <el-tag v-if="props.row.status == 1" type="success">ACTIVE</el-tag>
              <el-tag v-if="props.row.status == 2" type="info">INACTIVE</el-tag>
            </template>
          </el-table-column>

          <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Actions">
            <template slot-scope="props">
              <el-tooltip class="item" effect="dark" content="Deactivate account" placement="top-start">
                <el-button v-if="props.row.status" type="danger" size="small" class="ml-1" @click="changeStatus(props.row, 2)" :disabled="props.row.loading">{{ props.row.loading ? 'Processing':'DEACTIVATE!' }}</el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="dark" content="Activate" placement="top-start">
                <el-button v-if="!props.row.status" type="primary" size="small" class="ml-1" @click="changeStatus(props.row, 1)">ACTIVATE!</el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="dark" content="View" placement="top-start">
                <el-button type="primary" size="small" class="ml-1">
                  <router-link :to="{ name: 'UserEdit', params: { id: props.row.id } }" class="text-white"><i class="fa fa-eye"></i></router-link>
                </el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="dark" content="Delete" placement="top-start">
                <el-button type="info" size="small" class="ml-1" @click="handleDelete(props.row)">
                  <i class="fa fa-trash"></i>
                </el-button>
              </el-tooltip>

            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">Found {{ pagination.total }} data</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  User
} from '@/resources/user'

import Vue from 'vue'
import {
  Table,
  TableColumn,
  Select,
  Option,
  Drawer
} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import UserEditForm from './UserEditForm.vue'
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Drawer)

export default {
  components: {
    PPagination,
    UserEditForm
  },
  data() {

    return {
      loading: false,
      status: 1,
      showForm: false,
      showDelete: false,
      users: [],
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
      selected: null,
      searchQuery: '',
      propsToSearch: ['name', 'email', 'age'],
      userColumns: [{
          prop: 'name',
          label: 'Name',
          minWidth: 200
        },
        {
          prop: 'email',
          label: 'Email',
          minWidth: 250
        }
      ],
    }
  },
  watch: {
    status: function () {
      this.getUsers()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getUsers()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getUsers()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getUsers()
    }
  },
  created: function () {
    this.getUsers()
  },
  methods: {
    changeStatus(user, status) {
      this.loading = true
      user.loading = true
      User.update({
          id: user.id,
          status: status,
          status_only: true
        })
        .then(result => {
          this.getUsers()
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
          user.loading = false
        })
    },
    handleNew() {
      this.showForm = true
      this.selected = {}
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleDelete(data) {
      this.showDelete = true
    },
    deleteItem() {

    },
    getUsers() {
      this.loading = true
      User.get({
          params: {
            term: this.term,
            status: this.status,
            page: this.pagination.currentPage,
            per_page: this.pagination.perPage
          }
        })
        .then(result => {
          if (result.data.users)
            this.users = result.data.users
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">

</style>
