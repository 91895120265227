<template>
  <div class="row">
    <div class="col-md-12">
      <div v-if="user">ACCOUNT: {{ user.name }}</div>
      <div>
        <h4>Referred Clients</h4>
      </div>
    </div>
    <div class="col-md-12 card">

      <div class="card-body row">
        <div class="col-sm-7">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-3">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="users" style="width: 100%"
            v-loading="loading">
            <el-table-column :min-width="120" class-name="td-actions" label="Name">
              <template slot-scope="props">
                <div>{{ props.row.name }}</div>
                <div class="note">
                  ({{ props.row.role_names.join(", ") }})
                </div>
              </template>
            </el-table-column>
            <el-table-column :min-width="120" class-name="td-actions" label="Email">
              <template slot-scope="props">
                {{ props.row.email }}
              </template>
            </el-table-column>

            <el-table-column class-name="td-actions" label="Current Plan" :min-width="200">
              <template slot-scope="props">
                <div v-if="props.row.company_params">
                  <div v-if="props.row.company_params && props.row.company_params.subscription">
                    <div class="green-color">
                      {{ props.row.company_params && props.row.company_params.subscription ?
                      props.row.company_params.subscription.name
                      : 'No Plan Yet' }} -
                      {{ props.row.company_params && props.row.company_params.subscription ?
                      props.row.company_params.subscription.price
                      : '' }}
                    </div>
                    <div class="gray-text font-12">Start Date: {{ props.row.company_params &&
                      props.row.company_params.subscription ?
                      formatDate(props.row.company_params.subscription.start) : '' }}</div>
                    <div class="gray-text font-12">Due Date: {{ props.row.company_params &&
                      props.row.company_params.subscription
                      ?
                      formatDate(props.row.company_params.due_date) : '' }}</div>
                  </div>
                </div>

                <div v-else>
                  No Plan
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column :min-width="100" class-name="td-actions" label="Commission">
              <template slot-scope="props">
                {{ props.row.company_params ? props.row.company_params.referral_compensation_rate + " %" : '' }}
              </template>
            </el-table-column> -->

            <el-table-column :min-width="120" class-name="td-actions" label="Status">
              <template slot-scope="props">
                <div v-if="props.row.company_params">
                  <div>
                    <span class="text-success" v-if="props.row.company_params.company_plan.status == 1">ACTIVE</span>
                    <span class="text-success" v-if="props.row.company_params.company_plan.status == 2">EXPIRED</span>
                  </div>

                  <el-tag
                    v-if="props.row.company_params.minutes_before_due_date > 0 && props.row.company_params.minutes_before_due_date <= 4320"
                    type="warning">EXPIRES SOON</el-tag>

                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  UserReferral
} from '@/resources/user_referral'

import Vue from 'vue'
import {
  Table,
  TableColumn,
  Select,
  Option,
  Drawer
} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Drawer)

export default {
  props: ['user'],
  components: {
    PPagination,
  },
  data() {
    return {
      loading: false,
      term: null,
      users: [],
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
    }
  },
  watch: {
    user: function () {
      if (this.user) this.getUsers()
    },
    status: function () {
      this.getUsers()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getUsers()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getUsers()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getUsers()
    }
  },
  created: function () {
    this.getUsers()
  },
  methods: {
    formatDate(date, format) {
      return this.$moment(date).format(format || 'MM/DD/YYYY hh:mm')
    },
    getUsers() {
      this.loading = true
      UserReferral.getReferredUsers({
          term: this.term,
          status: this.status,
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
          user_id: this.user ? this.user.id : null
        })
        .then(result => {
          if (result.data.users)
            this.users = result.data.users
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
