<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <label>Amount</label>
            <el-input type="number" v-model="form.amount" placeholder="0.00" :step="0.01"></el-input>
          </div>

          <div class="form-group">
            <label>Remarks</label>
            <el-input type="textarea" v-model="form.remarks"></el-input>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { UserReferral } from '@/resources/user_referral'

export default {
  props: ['user','total_unclaimed'],
  data() {
    return {
      form: {
        amount: "",
        remarks: null,
      },
      loading: false
    };
  },
  methods: {
    submit() {
      this.loading = true

      this.form.user_id = this.user.id
      if (!this.form.amount) {
        this.$notify({
          type: 'danger',
          title: "Error",
          text: "Amount is required",
        });
        this.loading = false
        return false
      }

      if (this.form.amount > this.total_unclaimed) {
        this.$notify({
          type: 'danger',
          title: "Error",
          text: "Amount should not be greater than unclaimed commission "+this.total_unclaimed,
        });
        this.loading = false
        return false
      }

      UserReferral.payCommission(this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Saved successfully',
          });
          this.$emit('update', true)
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
      
    }
  }
}
</script>
<style></style>
