<template>
  <div>
    <el-drawer title="Preview" :visible.sync="showPreview" direction="btt" :before-close="deleteMail" size="100%">
      <div v-if="selected" class="row" v-loading="loading">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <view-mail :mail="selected" :validate="true"></view-mail>
          <hr>
          <div>Please click send to confirm this email or click delete to start again</div>
          <el-button size="small" type="success" plain @click="deleteMail">Delete</el-button>
          <el-button size="small" type="success" @click="validateMail">Send</el-button>
        </div>
      </div>
    </el-drawer>

    <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              Create Mail
            </h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>Recipient (Client)</label>
              <ValidationProvider name="recipient" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The recipient field is required' : null" :hasSuccess="passed"
                  name="recipient" v-model="form.user_id" :readonly="true" style="display:none">
                </fg-input>

                <search-users v-model="form.user_id" @update="setRecipient($event)" :passed="passed" :has_plan="true"
                  :error="failed ? 'The recipient field is required' : null"></search-users>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label>Subject</label>
              <div class="mb-2">
                <small class="orange-text">Click to use</small><br>
                <el-button size="small" v-for="subject in subject_helpers" :key="subject"
                  @click="useSubject(subject)">{{ subject }}</el-button>
              </div>
              <ValidationProvider name="subject" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The subject field is required' : null" :hasSuccess="passed"
                  name="subject" v-model="form.subject">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label>Message</label>
                  <div class="mb-2">
                    <small class="orange-text">Click to use</small><br>
                    <el-button size="small" v-for="message in message_helpers" :key="message"
                      @click="useMessage(message)">{{ message }}</el-button>
                  </div>
                  <ValidationProvider name="message" rules="required" v-slot="{ passed, failed }">
                    <fg-input :textarea="true" type="text" :error="failed ? 'The message field is required' : null"
                      :hasSuccess="passed" name="message" v-model="form.message">
                    </fg-input>

                  </ValidationProvider>

                </div>
              </div>
              <div class="col-md-4">

              </div>
            </div>

            <div class="form-group">
              <file-upload @change="setFiles($event)" :multiple="false"></file-upload>
            </div>

          </div>
          <div class="card-footer text-right">
            <p-button type="info" native-type="submit">Create</p-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required,
  email
} from "vee-validate/dist/rules";
import FileUpload from '../../Dashboard/Views/Components/FileUpload.vue';
import {
  Mail
} from '@/resources/mail';
import RemoteSearch from '../../Dashboard/Views/Components/RemoteSearch.vue';
import SearchUsers from '../../Dashboard/Views/Components/SearchUsers.vue';
import ViewMail from './ViewMail.vue';

extend("email", email);
extend("required", required);

export default {
  components: {
    FileUpload,
    RemoteSearch,
    SearchUsers,
    ViewMail
  },
  data() {
    return {
      showPreview: false,
      selected: null,
      form: {
        sender: "",
        subject: "",
        message: "",
        user_id: null
      },
      loading: false,
      files: [],
      message_helpers: [
        "Hi, attached is a new mail for you.",
        "Hello, there's a new mail waiting for you."
      ],
      subject_helpers: [
        "New Mail",
        "You've Got Mail",
        "Notification",
        "New Message"
      ]
    };
  },
  methods: {
    useMessage(message) {
      this.form.message = message
    },
    useSubject(subject) {
      this.form.subject = subject
    },
    setRecipient(user) {
      this.form.user_id = user ? user.id : null
    },
    setFiles(files) {
      this.files = files
    },
    deleteMail() {
      if (!this.selected) return false
      this.loading = true

      Mail.delete(this.selected)
        .then(result => {
          this.selected = null
          this.showPreview = false
        })
        .catch(error => {

        }).finally(() => {
          this.loading = false
        })
    },
    validateMail() {
      if (!this.selected) return false
      this.loading = true

      Mail.validate(this.selected.id, {
          is_valid: true
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: "Mail has been sent",
          });
          this.$router.push({
            name: 'AllMails'
          })
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.loading = false
        })
    },
    submit() {
      this.loading = true

      Mail.create(this.getFormData())
        .then(result => {

          this.selected = result.data.mail
          this.showPreview = true
          console.log(this.selected, result.data.mail)
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.loading = false
        })
    },
    getFormData() {
      var formData = new FormData()
      formData.append('sender', this.form.sender)
      formData.append('message', this.form.message)
      formData.append('subject', this.form.subject)
      formData.append('user_id', this.form.user_id)
      formData.append('is_valid', 0)

      this.files.forEach(file => {
        if (file.raw) formData.append('files[]', file.raw)
      });

      return formData
    }
  }
}
</script>

<style></style>
