import api from './api'

const API = '/api/mails'

export const Mail = {
  get: function(params) {
    return api.get(API, params)
  },
  all: function(params) {
    return api.get(API+'/all', params)
  },
  mailActionOptions: function(params) {
    return api.get(API+'/mail-action-options', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, {params: params})
  },
  checkMailActionsCount: function(id, params) {
    return api.get(API + '/' + id + '/check-mail-actions-count', {params: params})
  },
  create: function (formData) {
    return api.createFormData(API, formData)
  },
  updateCompany: function(params) {
    return api.update(API+'/'+params.id+'/update-company', params)
  },
  validate: function(id,form) {
    return api.update(API+'/'+id+'/validate', form)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(form) {
    return api.delete(API+'/'+form.id, form)
  }
}
