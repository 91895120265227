<template>
  <div>
    <div style="font-weight: bold;">Mail Box Address: {{ user.company.complete_address }}</div>
    <hr>
    <div class="row">
    
      <div class="col-md-3"  style="text-align: left;">
        <el-card class="black-bg white-text">
          <div style="font-size: 50px"> {{ user.stored_mails }}</div>
          <div>Mail{{ user.stored_mails > 1 ? 's':'' }} currently stored</div>
      </el-card>
      </div>

       <div class="col-md-4">
          <div class="form-group ">
            <label>MailBox Number</label>
            <el-input v-model="mailbox_number"></el-input>
          </div>

          <div class="form-group ">
              <label>Location</label>
              <el-select v-model="office_id">
                <el-option v-for="office in offices" :label="office.name + ' - ' + office.address.full_address" :key="office.id" :value="office.id">{{ office.name }} - {{ office.address.full_address }}</el-option>
              </el-select>
            </div>

                    <el-button @click="onSubmit()" :disabled="loading">{{ loading ? 'Saving' : 'Save' }}</el-button>

        </div>

    </div>
  </div>
</template>
<script>

import { User } from '@/resources/user'
import { Office } from '@/resources/office'

export default {
  props: ['user'],
  data() {
    return {
      loading: false,
      mailbox_number: null,
      office_id: null,
      offices: []
    }
  },
  watch: {
    user: function () {
      this.mailbox_number = this.user.company.company_plan.mailbox_number
      this.office_id = this.user.company && this.user.company.office ? this.user.company && this.user.company.office.id : null
    }
  },
  created: function () {
    this.mailbox_number = this.user.company.company_plan.mailbox_number
    this.office_id = this.user.company && this.user.company.office ? this.user.company && this.user.company.office.id : null
    this.getOffices()
  },
  methods: {
    getOffices() {
      Office.get({ params: { status: 1, page: 1, per_page: 999 } })
        .then(result => {
          if (result.data.offices)
            this.offices = result.data.offices
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    onSubmit() {
      this.loading = true
      User.saveMailBoxNumber(this.user.id, {mailbox_number: this.mailbox_number, office_id: this.office_id})
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Saved",
            text: 'Mailbox number has been saved'
          });
          this.$emit('update', true)
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
<style lang="scss"></style>
